import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';

interface IInstallment {
  installment: number;
  amount: number;
  total: number;
}

export interface IProductData {
  id: string;
  name: string;
  duration_in_months: number;
  price: number;
  discount: number;
  coupon_code: string | null;
  installments: IInstallment[];
}

interface IProductContextData {
  product: IProductData;
  final_price: number;
  selectedInstallment: number;
  setSelectedInstallment: (installment: number) => void;
}

interface IProductProviderProps {
  product: IProductData;
}

const ProductContext = createContext<IProductContextData>(
  {} as IProductContextData,
);

const ProductProvider: React.FC<IProductProviderProps> = ({
  children,
  product,
}) => {
  const [selectedInstallment, setSelectedInstallment] = useState(1);
  const [final_price, setFinalPrice] = useState(
    product.price - product.discount,
  );

  useEffect(() => {
    const installment = product.installments.find(
      i => i.installment === selectedInstallment,
    );

    if (installment) {
      setFinalPrice(installment.amount);
    }
  }, [selectedInstallment, product.installments]);

  const contextValues = useMemo(
    () => ({
      product,
      selectedInstallment,
      final_price,
      setSelectedInstallment,
    }),
    [product, selectedInstallment, final_price, setSelectedInstallment],
  );

  return (
    <ProductContext.Provider value={contextValues}>
      {children}
    </ProductContext.Provider>
  );
};

function useProduct(): IProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
}

export { ProductProvider, useProduct };
