import React from 'react';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <h1>Oops!</h1>

      <p>
        Parece que o link que você clicou está desatualizado ou incorreto. 😔
      </p>

      <p>
        Por favor,{' '}
        <a href="https://redacione.com.br" target="_blank" rel="noreferrer">
          clique aqui
        </a>{' '}
        para voltar para o nosso site.
      </p>
    </Container>
  );
};

export default NotFound;
