import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import ReCAPTCHA, { ReCAPTCHA as ReCAPTCHAProps } from 'react-google-recaptcha';
import { useLoading } from './loading';
import { useToast } from './toast';

interface ReCaptchaContextState {
  validateReCaptcha(): Promise<string | null | undefined>;
  resetReCaptcha(): void;
}

const ReCaptchaContext = createContext<ReCaptchaContextState>(
  {} as ReCaptchaContextState,
);

const ReCaptchaProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { stopLoading } = useLoading();

  const recaptchaRef = useRef<ReCAPTCHAProps>(null);

  const handleDOMChange = useCallback(() => {
    const iframe = document.querySelector(
      'iframe[src*="recaptcha/api2/bframe"]',
    );

    const container = iframe?.parentNode?.parentNode?.firstChild as
      | HTMLElement
      | undefined;

    if (!container) {
      return;
    }

    container.onclick = () => {
      stopLoading();

      addToast({
        title: 'Erro no CAPTCHA',
        description: 'Por favor, cumpra a etapa de verificação.',
        type: 'error',
      });
    };
  }, [stopLoading, addToast]);

  useEffect(() => {
    const domObserver = new MutationObserver(handleDOMChange);

    domObserver.observe(document.body, {
      childList: true,
    });
  }, [handleDOMChange]);

  const validateReCaptcha = useCallback(async () => {
    const token = await recaptchaRef.current?.executeAsync();

    return token;
  }, []);

  const resetReCaptcha = useCallback(async () => {
    recaptchaRef.current?.reset();
  }, []);

  const recaptchaValues = useMemo(
    () => ({ validateReCaptcha, resetReCaptcha }),
    [validateReCaptcha, resetReCaptcha],
  );

  return (
    <ReCaptchaContext.Provider value={recaptchaValues}>
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
        size="invisible"
        onErrored={() => console.log(`error: void`)}
        onExpired={() => console.log(`expired: void`)}
      />
    </ReCaptchaContext.Provider>
  );
};

function useReCaptcha(): ReCaptchaContextState {
  const context = useContext(ReCaptchaContext);

  if (!context) {
    throw new Error('useReCaptcha must be user within an ReCaptchaProvider');
  }

  return context;
}

export { ReCaptchaProvider, useReCaptcha };
