import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { MdContentCopy, MdInfo, MdTimer } from 'react-icons/md';
import Countdown from 'react-countdown';
import { useCharge } from '../../../hooks/charge';
import { useToast } from '../../../hooks/toast';
import Summary from '../Summary';

import {
  BilletDetails,
  BoxInfo,
  Container,
  PixDetails,
  CreditCardDetails,
  PaymentExt,
} from './styles';

const ChargeDetails: React.FC = () => {
  const { charge } = useCharge();
  const { addToast } = useToast();

  const handleCopy = useCallback(() => {
    const permissionName = 'clipboard-write' as PermissionName;

    navigator.permissions.query({ name: permissionName }).then(({ state }) => {
      if (state === 'granted' || state === 'prompt') {
        if (!charge.qr_code) {
          return;
        }

        navigator.clipboard.writeText(charge.qr_code).then(
          () => {
            addToast({
              title: 'Sucesso!',
              description: 'Código pix copiado com sucesso!',
              type: 'success',
            });
          },
          () => {
            addToast({
              title: 'Erro!',
              description: 'Ocorreu um erro ao copiar o código pix!',
              type: 'error',
            });
          },
        );
      }
    });
  }, [addToast, charge.qr_code]);

  return (
    <Summary>
      <Container>
        <div>
          <h2>Informações da compra</h2>

          {charge.type === 'pix' && (
            <>
              <PixDetails>
                <p>
                  Seu código Pix foi gerado com sucesso! 🤩
                  <br />O código pix irá expirar em <strong>10 minutos</strong>,
                  portanto, efetue o pagamento o quanto antes para ter acesso a
                  nossa Plataforma.
                </p>

                <img src={charge.qr_code_url} alt="Pix QR Code" />
              </PixDetails>

              <PaymentExt>
                <span>Código copia e cola</span>

                <p>{charge.qr_code}</p>

                <button type="button" onClick={handleCopy}>
                  <MdContentCopy size={20} />
                </button>
              </PaymentExt>

              <BoxInfo>
                <div>
                  <MdInfo size={24} />
                </div>

                <p>
                  Ao adquirir o plano via Pix, a liberação do acesso a
                  plataforma ocorrerá após a confirmação do pagamento. Isso
                  poderá levar alguns minutos. Assim que seu acesso for
                  liberado, você receberá um e-mail com a confirmação.
                </p>
              </BoxInfo>

              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                  left: 0,
                  background: '#77ffaa',
                  padding: '16px',
                  zIndex: 9,
                  gap: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#6c23c0',
                  fontSize: '16px',
                  flexDirection: 'column',
                }}
              >
                Efetue o pagamento em até:
                <Countdown
                  date={Date.now() + 1000 * 60 * 10}
                  onComplete={() => window.location.reload()}
                  renderer={({ formatted: { hours, minutes, seconds } }) => (
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <MdTimer size={20} /> {hours}:{minutes}:{seconds}
                    </span>
                  )}
                />
              </div>
            </>
          )}

          {charge.type === 'billet' && (
            <>
              <BilletDetails>
                <p>
                  Seu boleto foi gerado com sucesso! 🤩
                  <br />
                  Para ter acesso ao seu plano, pague o boleto até o dia{' '}
                  <strong>
                    {charge.boleto_expiration_date &&
                      format(
                        new Date(charge.boleto_expiration_date),
                        'dd/MM/yyyy',
                      )}
                  </strong>{' '}
                  e comece a estudar com a gente.
                </p>

                <div>
                  <a href={charge.boleto_url} target="_blank" rel="noreferrer">
                    Visualizar boleto
                  </a>
                </div>
              </BilletDetails>

              <BoxInfo>
                <div>
                  <MdInfo size={24} />
                </div>

                <p>
                  Pagamentos efetuados via boleto podem levar até{' '}
                  <strong>
                    3 dias úteis a partir da data de pagamento para serem
                    confirmados
                  </strong>
                  . Assim que o pagamento for confirmado, você receberá um
                  e-mail e poderá acessar a Plataforma Redacione normalmente.
                </p>
              </BoxInfo>
            </>
          )}

          {charge.type === 'credit_card' && (
            <CreditCardDetails>
              <p>
                Seu pagamento foi confirmado! 🤩
                <br />O pagamento referente ao seu pedido foi confirmado e você
                já possui acesso à Plataforma Redacione!
              </p>

              <div>
                <a href="https://plataforma.redacione.com.br">
                  Acessar plataforma
                </a>
              </div>
            </CreditCardDetails>
          )}
        </div>
      </Container>
    </Summary>
  );
};

export default ChargeDetails;
