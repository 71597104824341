import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { validateCep, validateCPF } from 'validations-br';
import axios from 'axios';
import { MdInfo } from 'react-icons/md';
import Address from '../../../../components/Address';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import api from '../../../../services/api';

import { Container } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useLoading } from '../../../../hooks/loading';
import { useStep } from '../../../../hooks/steps';
import { useCharge } from '../../../../hooks/charge';
import getPagarmeErrors from '../../../../utils/getPagarmeErrors';
import { useToast } from '../../../../hooks/toast';
import { useProduct } from '../../../../hooks/product';
import { BoxInfo } from '../../ChargeDetails/styles';

interface IBilletRegistrationData {
  cpf: string;
  name: string;
  zipcode: string;
  street: string;
  city: string;
  state: string;
}

const Billet: React.FC = () => {
  const { product } = useProduct();
  const { startLoading, stopLoading } = useLoading();
  const { stepForward } = useStep();
  const { addToast } = useToast();
  const { createCharge } = useCharge();

  const formRef = useRef<FormHandles>(null);

  const [zipcode, setZipcode] = useState('');

  const handleSubmit = useCallback(
    async (data: IBilletRegistrationData) => {
      startLoading();

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .test(
              'isFullName',
              'Digite seu nome completo',
              value => value?.trim().includes(' ') || false,
            )
            .required('Nome completo obrigatório'),
          cpf: Yup.string()
            .test('isCPF', 'Digite um CPF válido', value =>
              validateCPF(value || ''),
            )
            .required('CPF obrigatório'),
          zipcode: Yup.string()
            .length(9, 'Digite um CEP válido')
            .test('isCep', 'Digite um CEP válido', value =>
              validateCep(value || ''),
            )
            .required('CEP obrigatório'),
          street: Yup.string().required('Endereço obrigatório'),
          city: Yup.string().required('Cidade obrigatória'),
          state: Yup.string().required('Estado obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.post('/purchases', {
          product_id: product.id,
          payment_method: 'boleto',
          cpf: data.cpf.replace(/\D/g, ''),
          name: data.name,
          zipcode: data.zipcode.replace(/\D/g, ''),
          street: data.street,
          city: data.city,
          state: data.state,
        });

        createCharge({
          type: 'billet',
          payment_ext: response.data.payment_ext,
          boleto_url: response.data.boleto_url,
          boleto_expiration_date: response.data.boleto_expiration_date,
        });

        stepForward();

        stopLoading();
      } catch (err) {
        stopLoading();

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            title: 'Erro de validação',
            description: 'Verifique os campos com erro e tente novamente.',
            type: 'error',
          });

          return;
        }

        if (axios.isAxiosError(err)) {
          const { errors } = err.response?.data || { errors: null };
          let { error } = err.response?.data || {
            error: 'default',
          };

          if (typeof error === 'object') {
            error = getPagarmeErrors(error, 'object');
          }

          if (errors) {
            error = getPagarmeErrors(errors, 'array');
          }

          switch (error) {
            default:
              addToast({
                title: 'Erro Genério',
                description: error,
                type: 'error',
              });
              break;
          }
        }
      }
    },
    [startLoading, stopLoading, stepForward, createCharge, addToast, product],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <BoxInfo>
          <div>
            <MdInfo size={24} />
          </div>

          <p>
            Caso seja menor de idade, preencha o formulário abaixo com os dados
            do seu responsável!
          </p>
        </BoxInfo>

        <Input
          name="name"
          label="Nome completo"
          placeholder="Antônio José da Silva Oliveira"
        />

        <div className="split">
          <InputMask
            name="cpf"
            label="CPF"
            mask="999.999.999-99"
            maskChar={null}
            placeholder="000.000.000-00"
          />

          <InputMask
            name="zipcode"
            label="CEP"
            type="text"
            mask="99999-999"
            placeholder="00000-000"
            maskChar={null}
            onInput={e => {
              setZipcode(e.currentTarget.value.replace(/\D/g, '').slice(0, 8));
            }}
          />
        </div>

        <Address zipcode={zipcode} formRef={formRef} />

        <Button color="primary" type="submit">
          Gerar boleto
        </Button>
      </Form>
    </Container>
  );
};

export default Billet;
