import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import NotFound from '../../components/NotFound';

import StepProgress from '../../components/StepProgress';
import { useAuth } from '../../hooks/auth';
import { useLoading } from '../../hooks/loading';
import { IProductData, ProductProvider } from '../../hooks/product';
import { useStep } from '../../hooks/steps';
import api from '../../services/api';
import ChargeDetails from './ChargeDetails';
import PaymentMethod from './PaymentMethod';

import { Container } from './styles';

import UserAccount from './UserAccount';

const Default: React.FC = () => {
  const { product_id } = useParams<{ product_id: string }>();
  const { currentStep, stepForward } = useStep();
  const { isAuthenticated } = useAuth();
  const { startLoading, stopLoading, isLoading } = useLoading();

  const [product, setProduct] = useState({} as IProductData);

  const stepComponents = useMemo(
    () => [<UserAccount />, <PaymentMethod />, <ChargeDetails />],
    [],
  );

  useEffect(() => {
    async function loadData() {
      try {
        if (!product_id) {
          return;
        }

        startLoading();

        const searchParams = new URLSearchParams(document.location.search);

        const coupon_code = searchParams.get('coupon');

        const response = await api.get(`/products/${product_id}`, {
          params: {
            coupon_code,
          },
        });

        setProduct(response.data);
        stopLoading();
      } catch (err) {
        stopLoading();
      }
    }

    loadData();
  }, [product_id, startLoading, stopLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      stepForward();
    }
  }, [isAuthenticated, stepForward]);

  return (
    <Container>
      <header>
        <img
          src="https://redacione-api.s3.sa-east-1.amazonaws.com/site/logo-purple.svg"
          alt="Redacione"
        />
      </header>

      {!isLoading && !product.id && <NotFound />}
      {product.id && (
        <ProductProvider product={product}>
          <StepProgress
            steps={[
              'Criar conta',
              'Forma de pagamento',
              'Informações da compra',
            ]}
          />

          {stepComponents[currentStep]}
        </ProductProvider>
      )}

      <footer>
        <span className="contact">
          Em caso de problemas, entre em contato conosco enviando um e-mail para{' '}
          <a href="mailto:suporte@redacione.com.br">suporte@redacione.com.br</a>
          .
        </span>

        <div>
          <span>©2023 Redacione - Todos os Direitos Reservados.</span>

          <span>
            Desenvolvido por{' '}
            <a
              href="https://www.pxtecnologia.com.br"
              target="_blank"
              rel="noreferrer"
            >
              PX Tecnologia
            </a>
            .
          </span>
        </div>
      </footer>
    </Container>
  );
};

export default Default;
